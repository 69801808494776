import { lazy } from "react";
import { ChevronDown } from "react-feather";
import DataTable from "react-data-table-component";
import ContentLoader from "./Loader";
import PerPage from "./PerPage";
import "@styles/react/libs/tables/react-dataTable-component.scss";
const CustomPagination = lazy(() => import("./CustomPagination"));

const Table = (props) => {
  const {
    columns,
    data = [],
    handleSort,
    isLoading,
    totalPages = 1,
    currentPage,
    handlePagination,
    rowsPerPage,
    handlePerPage = () => {},
  } = props;

  return (
    <div className="react-dataTable">
      <DataTable
        pagination
        paginationServer
        className="react-dataTable"
        columns={columns}
        sortIcon={<ChevronDown size={10} />}
        paginationComponent={() => (
          <>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <PerPage
                rowsPerPage={rowsPerPage}
                handlePerPage={handlePerPage}
              />
              <CustomPagination
                totalPages={totalPages}
                currentPage={currentPage}
                handlePagination={handlePagination}
              />
            </div>
          </>
        )}
        data={data}
        onSort={handleSort}
        sortServer
        progressPending={isLoading}
        progressComponent={<ContentLoader />}
        allowOverflow={true}
      />
    </div>
  );
};
export default Table;
