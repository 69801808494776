/* eslint-disable multiline-ternary */
/* eslint-disable no-unused-vars */
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardLink,
  Input,
  Row,
  Col,
} from "reactstrap";
import Avatar from "../@core/components/avatar";
import Table from "../@core/components/table/Table";
import { useDebounce } from "../customHooks/useDebounce";
import { getTournamentList } from "../redux/user";
import { getClearObject } from "../utility/Utils";
import DateRange from "./Components/DateRange";

const Tournament = (props) => {
  const { getTournamentList, tournamentList } = props;

  const [tournamentData, setTournamentData] = useState([]);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [sorting, setSorting] = useState({
    orderBy: "",
    sortBy: "",
  });
  const [q, setQ] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const searchValue = useDebounce(q, 500);

  useEffect(() => {
    getTournamentList(
      getClearObject({
        limit: perPage,
        page: currentPage,
        sortBy: sorting?.sortBy,
        sort: sorting?.orderBy,
        searchQuery: searchValue,
        ...(startDate?.[0] && endDate?.[0]
          ? {
              "createdAt[between]": JSON.stringify([
                startDate?.[0],
                endDate?.[0],
              ]),
            }
          : startDate?.[0]
          ? {
              "createdAt[gte]": startDate?.[0],
            }
          : endDate?.[0] && {
              "createdAt[lte]": endDate?.[0],
            }),
      })
    );
  }, [
    currentPage,
    perPage,
    startDate,
    endDate,
    sorting?.orderBy,
    sorting?.sortBy,
    searchValue,
  ]);

  useEffect(() => {
    if (!tournamentList?.loading) {
      setTournamentData(
        tournamentList?.data?.data?.rows?.map((item, index) => {
          return {
            ...item,
            no: index + 1,
            startDate: moment(item?.startDate)?.format("lll"),
            endDate: moment(item?.endDate)?.format("lll"),
          };
        })
      );
    }
  }, [tournamentList]);

  const handlePerPage = (e) => {
    setPerPage(e?.target?.value);
    setCurrentPage(1);
  };

  const handlePagination = (page) => {
    setCurrentPage(page?.selected + 1);
  };

  const handleSort = (column, sortDirection) => {
    setSorting({
      orderBy: column._dbName,
      sortBy: sortDirection,
    });
  };

  const dataColumns = [
    {
      sortable: false,
      name: "No.",
      selector: (row) => row.no ?? "-",
      _dbName: "no",
      width: "80px",
      wrap: true,
    },
    {
      sortable: true,
      name: "Name",
      selector: (row) => row.name ?? "-",
      _dbName: "name",
      wrap: true,
    },
    {
      sortable: true,
      name: "Start Date",
      wrap: true,
      selector: (row) => row.startDate ?? "-",
      _dbName: "startDate",
    },
    {
      sortable: true,
      name: "End Date",
      selector: (row) => row.endDate ?? "-",
      _dbName: "endDate",
      wrap: true,
    },
    {
      sortable: true,
      name: "Created By",
      selector: (row) => row.user.name ?? "-",
      _dbName: "user.name",
      wrap: true,
    },
    {
      sortable: true,
      name: "Academy Name",
      selector: (row) => row.academy.name ?? "-",
      _dbName: "academy.name",
      wrap: true,
    },
    {
      sortable: true,
      name: "Payment Method",
      selector: (row) => row.paymentMethod ?? "-",
      _dbName: "paymentMethod",
      wrap: true,
    },
    {
      sortable: true,
      name: "Amount",
      selector: (row) => row.amount ?? "-",
      _dbName: "amount",
      wrap: true,
    },
    {
      sortable: true,
      name: "Gender",
      selector: (row) => row.gender ?? "-",
      _dbName: "gender",
      wrap: true,
    },
  ];

  return (
    <div>
      <DateRange
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />
      <Card>
        <CardHeader className="border-bottom">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <CardTitle>Tournaments </CardTitle>
            <Input
              value={q}
              onChange={(e) => setQ(e?.target?.value)}
              placeholder="Search"
              style={{ width: "auto" }}
            />
          </div>
        </CardHeader>
        <CardBody>
          <Table
            columns={dataColumns}
            data={tournamentData || []}
            handleSort={handleSort}
            isLoading={tournamentList?.loading}
            totalPages={(tournamentList?.data?.data?.count || 1) / perPage}
            currentPage={currentPage}
            handlePagination={handlePagination}
            rowsPerPage={perPage}
            handlePerPage={handlePerPage}
          />
        </CardBody>
      </Card>
    </div>
  );
};

const mapStateToProps = (state) => ({
  tournamentList: state.user.tournamentList,
});
const mapDispatchToProps = (dispatch) => ({
  getTournamentList: (payload) => dispatch(getTournamentList(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Tournament);
