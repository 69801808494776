import { Input, Label } from "reactstrap";

const PerPage = (props) => {
  const { rowsPerPage, handlePerPage } = props;

  return (
    <>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Label for="sort-select">show</Label>
        <Input
          className="dataTable-select"
          type="select"
          id="sort-select"
          value={rowsPerPage}
          onChange={(e) => handlePerPage(e)}
          style={{ marginRight: "10px", marginLeft: "10px" }}
        >
          <option value={10}>10</option>
          <option value={25}>25</option>
          <option value={50}>50</option>
          <option value={75}>75</option>
          <option value={100}>100</option>
        </Input>
        <Label for="sort-select">entries</Label>
      </div>
    </>
  );
};
export default PerPage;
