const ContentLoader = () => {
  return (
    <div className="ymha-content-spinner app-loader">
      <div className="loading">
        <div className="effect-1 effects"></div>
        <div className="effect-2 effects"></div>
        <div className="effect-3 effects"></div>
      </div>
      <br />
      <h5 className="text-primary">Fetching Data... Please Wait</h5>
    </div>
  );
};

export default ContentLoader;
