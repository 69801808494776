/* eslint-disable no-duplicate-imports */
/* eslint-disable prefer-const */
import { useState, useEffect } from "react";

/* eslint-disable no-unused-vars */
export const useDebounce = (text = "", time = 500) => {
  const [value, setValue] = useState(text);

  useEffect(() => {
    let interval = setTimeout(() => {
      setValue(text);
    }, time);

    return () => clearTimeout(interval);
  }, [text, time]);

  return value;
};
