/* eslint-disable prefer-const */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import { API_ENDPOINTS } from "../configs/endpoints";
import API from "../service";
import { AccessToken } from "../utility/Utils";

const initialState = {
  token: "",
  loginDetail: {
    loading: false,
    data: {},
    error: "",
  },
  adminDetail: {
    loading: false,
    data: {},
    error: " ",
  },
  usersList: {
    loading: false,
    data: {},
    error: "",
  },
  coachList: {
    loading: false,
    data: {},
    error: "",
  },
  academyList: {
    loading: false,
    data: {},
    error: "",
  },
  tournamentList: {
    loading: false,
    data: {},
    error: "",
  },
  transactionsList: {
    loading: false,
    data: {},
    error: "",
  },
  feedbackList: {
    loading: false,
    data: {},
    error: "",
  },
  subscriptionList: {
    loading: false,
    data: {},
    error: "",
  },
  addSubscriptionData: {
    loading: false,
    data: {},
    error: "",
  },
  updateSubscriptionData: {
    loading: false,
    data: {},
    error: "",
  },
  deleteSubscriptionData: {
    loading: false,
    data: {},
    error: "",
  },
};

export const login = createAsyncThunk("ADMIN_LOGIN", async (params) => {
  const res = await API.post(API_ENDPOINTS.ADMIN_LOGIN, params);
  if (res?.status == "success") {
    AccessToken.set(res?.token);
    toast.success(res?.message);
  } else {
    toast.error(res?.message, { duration: 3000 });
  }
  return res;
});

export const getAdminDetail = createAsyncThunk("GET_ADMIN_DETAIL", async () => {
  const res = await API.get(API_ENDPOINTS.ADMIN_DETAIL);
  return res;
});

export const getUserList = createAsyncThunk("GET_USER_LIST", async (params) => {
  return await API.get(API_ENDPOINTS.GET_USERS_LIST, params);
});

export const getCoachList = createAsyncThunk(
  "GET_COACH_LIST",
  async (params) => {
    return await API.get(API_ENDPOINTS.GET_COACH_LIST, params);
  }
);
export const getAcademyList = createAsyncThunk(
  "GET_ACADEMY_LIST",
  async (params) => {
    return await API.get(API_ENDPOINTS.GET_ACADEMY_LIST, params);
  }
);

export const getTournamentList = createAsyncThunk(
  "GET_TOURNAMENT_LIST",
  async (params) => {
    return await API.get(API_ENDPOINTS.GET_TOURNAMENT_LIST, params);
  }
);
export const getTransactionsList = createAsyncThunk(
  "GET_TRANSACTIONS_LIST",
  async (params) => {
    return await API.get(API_ENDPOINTS.GET_TRANSACTIONS_LIST, params);
  }
);

export const getFeedbackList = createAsyncThunk(
  "GET_FEEDBACKS_LISt",
  async (params) => {
    return await API.get(API_ENDPOINTS.GET_FEEDBACKS_LIST, params);
  }
);

export const getSubscriptionList = createAsyncThunk(
  "GET_SUBSCRIPTION_LIST",
  async (params) => {
    return await API.get(API_ENDPOINTS.GET_SUBSCRIPTIONS_LIST, params);
  }
);

export const addSubscription = createAsyncThunk(
  "ADD_SUBSCRIPTION",
  async (params, { getState }) => {
    const res = await API.post(API_ENDPOINTS.GET_SUBSCRIPTIONS_LIST, params);
    if (res.status == "success") {
      toast.success(res?.message);
    } else {
      toast.error(res?.message, { duration: 3000 });
    }
    return { ...res, subscriptionList: getState()?.user?.subscriptionList };
  }
);

export const updateSubscription = createAsyncThunk(
  "UPDATE_SUBSCRIPTION",
  async (params, { getState }) => {
    const res = await API.patch(
      `${API_ENDPOINTS.GET_SUBSCRIPTIONS_LIST}/${params?.id}`,
      params?.data
    );
    if (res.status == "success") {
      toast.success(res?.message);
    } else {
      toast.error(res?.message, { duration: 3000 });
    }
    return { ...res, subscriptionList: getState()?.user?.subscriptionList };
  }
);

export const deleteSubscription = createAsyncThunk(
  "DELETE_SUBSCRIPTION",
  async (params, { getState }) => {
    const res = await API.deleteM(
      `${API_ENDPOINTS.GET_SUBSCRIPTIONS_LIST}/${params?.id}`
    );
    if (res.status == "success") {
      toast.success(res?.message);
    } else {
      toast.error(res?.message, { duration: 3000 });
    }
    return {
      ...res,
      id: params?.id,
      subscriptionList: getState()?.user?.subscriptionList,
    };
  }
);

export const authSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {
        return {
          ...state,
          loginDetail: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(login.fulfilled, (state, action) => {
        return {
          ...state,
          loginDetail: {
            loading: false,
            data: action.payload,
            error: "",
          },
          token: action.payload.token,
        };
      })
      .addCase(login.rejected, (state, action) => {
        return {
          ...state,
          loginDetail: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getAdminDetail.pending, (state, action) => {
        return {
          ...state,
          adminDetail: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getAdminDetail.fulfilled, (state, action) => {
        return {
          ...state,
          adminDetail: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getAdminDetail.rejected, (state, action) => {
        return {
          ...state,
          adminDetail: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getUserList.pending, (state, action) => {
        return {
          ...state,
          usersList: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getUserList.fulfilled, (state, action) => {
        return {
          ...state,
          usersList: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getUserList.rejected, (state, action) => {
        return {
          ...state,
          usersList: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getCoachList.pending, (state, action) => {
        return {
          ...state,
          coachList: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getCoachList.fulfilled, (state, action) => {
        return {
          ...state,
          coachList: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getCoachList.rejected, (state, action) => {
        return {
          ...state,
          coachList: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getAcademyList.pending, (state, action) => {
        return {
          ...state,
          academyList: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getAcademyList.fulfilled, (state, action) => {
        return {
          ...state,
          academyList: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getAcademyList.rejected, (state, action) => {
        return {
          ...state,
          academyList: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getTournamentList.pending, (state, action) => {
        return {
          ...state,
          tournamentList: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getTournamentList.fulfilled, (state, action) => {
        return {
          ...state,
          tournamentList: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getTournamentList.rejected, (state, action) => {
        return {
          ...state,
          tournamentList: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getTransactionsList.pending, (state, action) => {
        return {
          ...state,
          transactionsList: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getTransactionsList.fulfilled, (state, action) => {
        return {
          ...state,
          transactionsList: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getTransactionsList.rejected, (state, action) => {
        return {
          ...state,
          transactionsList: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getFeedbackList.pending, (state, action) => {
        return {
          ...state,
          feedbackList: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getFeedbackList.fulfilled, (state, action) => {
        return {
          ...state,
          feedbackList: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getFeedbackList.rejected, (state, action) => {
        return {
          ...state,
          feedbackList: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(getSubscriptionList.pending, (state, action) => {
        return {
          ...state,
          subscriptionList: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getSubscriptionList.fulfilled, (state, action) => {
        return {
          ...state,
          subscriptionList: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getSubscriptionList.rejected, (state, action) => {
        return {
          ...state,
          subscriptionList: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(addSubscription.pending, (state, action) => {
        return {
          ...state,
          addSubscriptionData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(addSubscription.fulfilled, (state, action) => {
        let oldList = action?.payload?.subscriptionList?.data?.data || [];
        if (action?.payload?.status) {
          oldList = [action?.payload?.data, ...oldList];
        }
        return {
          ...state,
          addSubscriptionData: {
            loading: false,
            data: action.payload,
            error: "",
          },
          subscriptionList: {
            ...state?.subscriptionList,
            data: {
              ...state?.subscriptionList?.data,
              data: oldList,
            },
          },
        };
      })
      .addCase(addSubscription.rejected, (state, action) => {
        return {
          ...state,
          addSubscriptionData: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(updateSubscription.pending, (state, action) => {
        return {
          ...state,
          updateSubscriptionData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(updateSubscription.fulfilled, (state, action) => {
        return {
          ...state,
          updateSubscriptionData: {
            loading: false,
            data: action.payload,
            error: "",
          },
          subscriptionList: {
            ...state?.subscriptionList,
            data: {
              ...state?.subscriptionList?.data,
              data: action?.payload?.subscriptionList?.data?.data?.map(
                (item) => {
                  if (item?.id == action?.payload?.data?.id) {
                    return {
                      ...item,
                      ...action.payload.data,
                    };
                  } else {
                    return item;
                  }
                }
              ),
            },
          },
        };
      })
      .addCase(updateSubscription.rejected, (state, action) => {
        return {
          ...state,
          updateSubscriptionData: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      })
      .addCase(deleteSubscription.pending, (state, action) => {
        return {
          ...state,
          deleteSubscriptionData: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(deleteSubscription.fulfilled, (state, action) => {
        return {
          ...state,
          deleteSubscriptionData: {
            loading: false,
            data: action.payload,
            error: "",
          },
          subscriptionList: {
            ...state?.subscriptionList,
            data: {
              ...state?.subscriptionList?.data,
              data: action?.payload?.subscriptionList?.data?.data?.filter(
                (item) => item?.id != action?.payload?.id
              ),
            },
          },
        };
      })
      .addCase(deleteSubscription.rejected, (state, action) => {
        return {
          ...state,
          deleteSubscriptionData: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      });
  },
});

export default authSlice.reducer;
