/* eslint-disable no-unused-vars */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_ENDPOINTS } from "../configs/endpoints";
import API from "../service";

const initialState = {
  dahsboardOverview: {
    loading: false,
    data: {},
    error: "",
  },
};

export const getDashboardOverview = createAsyncThunk(
  "GET_DASHBOARD_OVERVIEW",
  async (params) => {
    return await API.get(API_ENDPOINTS.GET_DASHBOARD_OVERVIEW, params);
  }
);

export const dashboardSlice = createSlice({
  name: "dashboard",
  initialState: {},
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getDashboardOverview.pending, (state, action) => {
        return {
          ...state,
          dahsboardOverview: {
            loading: true,
            data: {},
            error: "",
          },
        };
      })
      .addCase(getDashboardOverview.fulfilled, (state, action) => {
        return {
          ...state,
          dahsboardOverview: {
            loading: false,
            data: action.payload,
            error: "",
          },
        };
      })
      .addCase(getDashboardOverview.rejected, (state, action) => {
        return {
          ...state,
          dahsboardOverview: {
            loading: false,
            data: {},
            error: action.payload,
          },
        };
      });
  },
});

export default dashboardSlice.reducer;
