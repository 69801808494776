import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Label,
  Row,
} from "reactstrap";
import Flatpickr from "react-flatpickr";
import "@styles/base/plugins/forms/pickers/form-flat-pickr.scss";
import "flatpickr/dist/flatpickr.css";
import "@styles/react/libs/flatpickr/flatpickr.scss";

const DateRange = (props) => {
  const { startDate, endDate, setStartDate, setEndDate } = props;

  return (
    <Card>
      <CardHeader>
        <CardTitle>Date Range </CardTitle>
      </CardHeader>
      <CardBody>
        <Row>
          <Col xxl={6} lg={6} xs={12}>
            <Label className="form-label" for="default-picker1">
              Start Date
            </Label>
            <Flatpickr
              className="form-control"
              value={startDate}
              onChange={(e) => {
                setStartDate(e);
              }}
              id="default-picker1"
              placeholder="Start Date"
            />
          </Col>
          <Col xxl={6} xs={12} lg={6}>
            <Label className="form-label" for="default-picker2">
              End Date
            </Label>
            <Flatpickr
              className="form-control"
              value={endDate}
              onChange={(e) => {
                setEndDate(e);
              }}
              id="default-picker2"
              placeholder="End Date"
              options={{ minDate: new Date(startDate) }}
            />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default DateRange;
